import React from 'react';
import {Typography} from '@mui/material';

// import undercurve from '../../../assets/images/undercurve.svg';
import tutor1 from '../../../assets/images/tutors/mannix-tutor.webp';
import tutor2 from '../../../assets/images/tutors/steffi-tutor.webp';
import tutor3 from '../../../assets/images/tutors/zainhab-tutor.webp';
import tutor4 from '../../../assets/images/tutors/jonah-tutor.webp';
import tutor5 from '../../../assets/images/tutors/lewis-tutor.webp';
import tutor6 from '../../../assets/images/tutors/peter-tutor.webp';
import tutor7 from '../../../assets/images/parents/parents1.webp';
import tutor8 from '../../../assets/images/parents/parents2.webp';
import config from 'config';

const tutors = [
  {
    id: 1,
    name: "Mannix M",
    image: tutor1,
  },
  {
    id: 2,
    name: "Steffi H",
    image: tutor2,
  },
  {
    id: 3,
    name: "Zainab A",
    image: tutor3,
  },
  {
    id: 4,
    name: "Jonah",
    image: tutor4,
  },
  {
    id: 5,
    name: "Lewis",
    image: tutor5,
  },
  {
    id: 6,
    name: "Peter D",
    image: tutor6,
  },
  {
    id: 7,
    name: "Parents1",
    image: tutor7,
  },
  {
    id: 8,
    name: "Parents2",
    image: tutor8,
  },
];

// Refer to tutor by ID, instead of array pos in tutors array
const tutorByID = (id) => tutors.find((tutor) => tutor.id === id);

const RowComponent = ({
  title,
  content,
  ID1,
  ID2,
  type,
  rowDirection = "",
}) => {
  const colourClassName =
    config["class-name"] === "math" ? "primary-colour" : "secondary-colour";
  return (
    <>
      <section>
        <div>
          <div className={`content-row ${rowDirection}`}>
            <div>
              <Typography variant="h4" color={"primary"}>
                {title}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ lineHeight: { sm: "24px", md: "25px" } }}
              >
                {content}
              </Typography>
            </div>

            <div className={`image-container ${type} ${config["class-name"]}`}>
              <img
                src={tutorByID(ID1)?.image}
                alt={tutorByID(ID1)?.name}
                loading="lazy"
              />
              <img
                src={tutorByID(ID2)?.image}
                alt={tutorByID(ID2)?.name}
                loading="lazy"
              />
              {ID1 && type === "tutor" && (
                <div>
                  <p className="contentrow-tutor-name">
                    {tutorByID(ID1)?.name}
                  </p>
                </div>
              )}
              {ID2 && type === "tutor" && (
                <div>
                  <p className="contentrow-tutor-name">
                    {tutorByID(ID2)?.name}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Rows = () => {
  return (
    <>
      <RowComponent
        title="No commission, No finder's fee"
        content="We are NOT an agency, and we do NOT charge any commission or take a cut of the tutor's 
                    earnings. There are no hidden costs involved."
        type="tutor"
        ID1={1}
        ID2={2}
        rowDirection=""
      />

      <RowComponent
        title="Parents are in control"
        content="Parents you are in complete control.
                    Contact tutors freely and without
                    limitations. Reach out to as many as you
                    need to find the perfect match for your
                    child."
        type="parents"
        ID1={7}
        ID2={8}
        rowDirection="row-reverse"
      />
      <RowComponent
        title="Each tutor is different"
        content="Your ideal tutor may not be
                    the most experienced, but rather someone you connect with to
                    ensure effective learning."
        type="tutor"
        ID1={3}
        ID2={6}
        rowDirection=""
      />
    </>
  );
};

export default Rows;
